import Layout from "containers/Layouts/Layout";
import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import InputMask from "react-input-mask";
import configs from "config";
import pix from "assets/imgs/pix.png";
import cardValidator from 'card-validator';
import {
  BackCart,
  BoxDiv2,
  BoxPay,
  BoxPix,
  Button,
  Button2,
  Content,
  ContentAdress,
  ContentPerson,
  ContentPay,
  Div2,
  DivMobile,
  DivInputs,
  DivInputsAdress,
  DivInputsAdressOne,
  Header,
  HeaderMobile,
  InputDados,
  InputRadio,
  MenuItem,
  MenuItemPay,
  ProductDetails,
  ProductImage,
  ProductPrice,
  RemoveButton,
  RenderContentPay,
  Section,
  SectionAdress,
  SectionBoleto,
  SectionCartao,
  SectionDados,
  SectionEnd,
  SectionEndereco,
  SectionPagamento,
  SectionProduct,
  SectionSub,
  SectionVale,
  Title,
  TitleAd,
  TitleAdress,
  TitleDp,
  TitleEnd,
  TitlePay,
  ButtonMobile,
  ButtonEntrega
} from "./styles";
import { CartContext } from "context/CartContext";
import NoImage from "assets/imgs/nomedia.png";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import Loader2 from "../../components/Loader2";
// import configs from "config";
import { useAuth } from "context/AuthContext";
import PagamentoBoleto from "components/Payments/pagamentoBoleto";
import PagamentoPix from "components/Payments/pagamentoPix";
import { BsTrash } from "react-icons/bs";
import ModalAviso from "components/ModalAviso";
import PagamentoCartao from "components/Payments/pagamentoCartão";
// import { CheckboxLabel } from "components/Filter/styles";

const Checkout = () => {
  const [activeMenuItem, setActiveMenuItem] = useState("dados-pessoais");
  const [activeMenuItemPay, setActiveMenuItemPay] = useState("");
  const [dadosPessoaisPreenchidos, setDadosPessoaisPreenchidos] =
    useState(false);
  const [enderecoPreenchido, setEnderecoPreenchido] = useState(false);
  const [total, setTotal] = useState(0);
  const [subTotal, setSubTotal] = useState(0);
  const [showFinalizarCompra, setShowFinalizarCompra] = useState(false);
  const token = Cookies.get("authToken");
  const { cartItems, removeFromCart, clearCart } = useContext(CartContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);
  const [finalizarCompra, setIsFinalizarCompra] = useState(true);
  const [paymentOrder, setPaymentOrder] = useState("");
  const [prazoEntregaSedex, setPrazoEntregaSedex] = useState("");
  const [precoEntregaSedex, setPrecoEntregaSedex] = useState("");
  const [prazoEntregaPac, setPrazoEntregaPac] = useState("");
  const [precoEntregaPac, setPrecoEntregaPac] = useState("");
  const [dataUsers, setDataUsers] = useState(null);
  const [totalOrder, setTotalOrder] = useState("");
  const [pageBoleto, setPageBoleto] = useState(false);
  const [pagePix, setPagePix] = useState(false);
  const [pageCartao, setPageCartao] = useState(false);
  const { id } = useAuth();
  const [modalAvisoVisible, setModalAvisoVisible] = useState(false);
  const [modalAvisoMensagem, setModalAvisoMensagem] = useState("");
  const [tipoEntrega, setTipoEntrega] = useState("sedex");
  const [isBefore13h, setIsBefore13h] = useState(true);
  const [entregaHoje, setEntregaHoje] = useState(false);
  const [sextaFeira, setSextaFeira] = useState(false);
  const [desconto, setDesconto] = useState(false);
  const [entregaAmanha, setEntregaAmanha] = useState(false);
  const [onlyMotoboy, setOnlyMotoboy] = useState(false);


  const [precoEntrega, setPrecoEntrega] = useState("");
  const [feriado, setFeriado] = useState(false);
  const [feriado2, setFeriado2] = useState(false);
  const [visibleResumo] = useState(false);
  const renderParcelas = () => {
    const parcelas = [];
    const valorMinimoParcela = 300;
    const maxParcelas = 10;

    for (let i = 1; i <= maxParcelas; i++) {
      let valorParcela = total / i;
      if (valorParcela >= valorMinimoParcela) {
        parcelas.push(
          <option key={i} value={i}>
            {i}x - R$ {valorParcela.toFixed(2)}
          </option>
        );
      }
    }

    if (parcelas.length === 0) {
      parcelas.push(
        <option key={1} value={1}>
          1x - R$ {total.toFixed(2)}
        </option>
      )
    }

    return parcelas;
  };
  function formatCpfCnpj(cpfCnpj) {
    if (cpfCnpj) {
      if (cpfCnpj.length === 11) {
        return `${cpfCnpj.slice(0, 3)}.${cpfCnpj.slice(3, 6)}.${cpfCnpj.slice(
          6,
          9
        )}-${cpfCnpj.slice(9, 11)}`;
      } else if (cpfCnpj.length === 14) {
        return `${cpfCnpj.slice(0, 2)}.${cpfCnpj.slice(2, 5)}.${cpfCnpj.slice(
          5,
          8
        )}/${cpfCnpj.slice(8, 12)}-${cpfCnpj.slice(12, 14)}`;
      } else {
        return cpfCnpj;
      }
    }
  }



  const closeModalAviso = () => {
    setModalAvisoVisible(false);
  };
  // const handleVisibleResumo = () => {
  //   setVisibleResumo((visibleResumo) => !visibleResumo);
  // };
  useEffect(() => {
    const calculateTotal = () => {
      let newTotal = 0;
      let subTotal = 0;

      cartItems.forEach((item) => {
        const salePriceAsNumber = parseFloat(item.salePrice);
        if (!isNaN(salePriceAsNumber)) {
          subTotal += salePriceAsNumber;
          newTotal += salePriceAsNumber;
        }

      });
      let deliveryCost = 0;

      if (tipoEntrega === "pac") {
        let precoEntregaAsString = precoEntregaPac.toString();

        let precoEntregaAsNumber = parseFloat(
          precoEntregaAsString.replace(",", ".")
        );
        deliveryCost = precoEntregaAsNumber;
        setPrecoEntrega(deliveryCost);
      } else if (tipoEntrega === "sedex") {
        let precoEntregaAsString = precoEntregaSedex.toString();

        let precoEntregaAsNumber = parseFloat(
          precoEntregaAsString.replace(",", ".")
        );

        deliveryCost = precoEntregaAsNumber;
        setPrecoEntrega(deliveryCost);
      } else if (tipoEntrega === "motoboy") {
        deliveryCost = 35.0;
        setPrecoEntrega(deliveryCost);
      }

      newTotal += deliveryCost;

      setSubTotal(subTotal);
      setTotal(newTotal);
      if (activeMenuItemPay === "pix") {
        const valorDesconto = subTotal * 0.05;
        newTotal -= valorDesconto;

        setTotalOrder(newTotal);
        setDesconto(valorDesconto);


      } else {
        setTotalOrder(newTotal);
      }
    };

    calculateTotal();
  }, [cartItems, tipoEntrega, precoEntregaPac, precoEntregaSedex, totalOrder, activeMenuItemPay, desconto]);

  useEffect(() => {
    if (dataUsers?.name) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        nome: dataUsers?.name,
        email: dataUsers?.email,
        cpf_cnpj: formatCpfCnpj(dataUsers?.CPF_CNPJ),
        telefone: dataUsers?.numero_celular,
      }));
    }
  }, [
    dataUsers?.name,
    dataUsers?.email,
    dataUsers?.CPF_CNPJ,
    dataUsers?.numero_celular,
  ]);

  useEffect(() => {
    if (!pagePix && !pageBoleto && !pageCartao) {
      if (!cartItems || cartItems.length === 0) {
        let countdown = 5;
        const interval = setInterval(() => {
          countdown--;
          if (countdown === 0) {
            clearInterval(interval);
            setTimeout(() => {
              window.location.href = '/shop';
              setModalAvisoVisible(false);
            }, 500);
          } else {
            setModalAvisoVisible(true);
            setModalAvisoMensagem(`Não existem produtos para serem pagos, redirecionando para a loja em ${countdown}...`);
          }
        }, 1000);

        return () => clearInterval(interval);
      }
    }
  }, [cartItems, pagePix, pageBoleto, pageCartao]);

  const [formData, setFormData] = useState({
    nome: "",
    email: "",
    cpf_cnpj: "",
    telefone: "",
  });

  useEffect(() => {
    if (dataUsers?.user_cep) {
      setAddressData((prevAddressData) => ({
        ...prevAddressData,
        cep: dataUsers?.user_cep,
        logradouro: dataUsers?.user_logradouro,
        numero: dataUsers?.user_numero,
        complemento: dataUsers?.user_complementologradouro,
        bairro: dataUsers?.user_bairro,
        cidade: dataUsers?.user_municipio,
        estado: dataUsers?.user_estado,
        destinatario: dataUsers?.user_destinatario,
      }));
    }
  }, [
    dataUsers?.user_cep,
    dataUsers?.user_logradouro,
    dataUsers?.user_numero,
    dataUsers?.user_complementologradouro,
    dataUsers?.user_bairro,
    dataUsers?.user_municipio,
    dataUsers?.user_estado,
    dataUsers?.user_destinatario,
  ]);
  const [addressData, setAddressData] = useState({
    cep: "",
    logradouro: "",
    numero: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
    destinatario: "",
  });
  const [cardData, setCardData] = useState({
    holder: "",
    number: "",
    installments: 1,
    expMonth: "",
    expYear: "",
    securityCode: "",
    cpfCnpj: "",
  });
  const [currentDate, setCurrentDate] = useState("");
  const [nextDate, setNextDate] = useState("");

  useEffect(() => {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    // Formatando as datas
    const formattedToday = formatDate(today);
    const formattedTomorrow = formatDate(tomorrow);

    // Atualizando os estados
    setCurrentDate(formattedToday);
    setNextDate(formattedTomorrow);
  }, []);

  const formatDate = (date) => {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    };
    return date.toLocaleDateString("pt-BR", options);
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  useEffect(() => {
    const allFieldsFilled = Object.values({
      ...formData,
    }).every((field) => field !== "");
    setDadosPessoaisPreenchidos(allFieldsFilled);
  }, [formData]);

  const validatePersonalData = () => {
    const { nome, email, cpf_cnpj, telefone } = formData;

    if (!nome || !email || !cpf_cnpj || !telefone) {
      setModalAvisoVisible(true);
      setModalAvisoMensagem("Preencha todos os campos de dados pessoais.");
      return false;
    }
    if (!isValidCPF(cpf_cnpj)) {
      setModalAvisoVisible(true);
      setModalAvisoMensagem("CPF inválido. Verifique o formato.");
      return false;
    }
    if (!isValidEmail(email)) {
      setModalAvisoVisible(true);
      setModalAvisoMensagem("E-mail inválido. Verifique o formato.");
      return false;
    }
    return true;
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 0,
    }).format(value);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };
  const handleEntregaHoje = () => {
    setEntregaHoje(true);
    setEntregaAmanha(false);

  };
  const handleEntregaAmanha = () => {
    setEntregaAmanha(true);
    setEntregaHoje(false);

  };
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAddressChange = (event) => {
    const { name, value } = event.target;
    setAddressData((prevAddressData) => ({
      ...prevAddressData,
      [name]: value,
    }));

    const { complemento, ...addressWithoutComplemento } = {
      ...addressData,
      [name]: value,
    };
    const allFieldsFilled = Object.values(addressWithoutComplemento).every(
      (field) => field !== ""
    );
    setEnderecoPreenchido(allFieldsFilled);
  };

  const validateAddress = () => {
    const { cep, logradouro, numero, bairro, cidade, estado, destinatario } =
      addressData;
    if (
      !cep ||
      !logradouro ||
      !numero ||
      !bairro ||
      !cidade ||
      !estado ||
      !destinatario
    ) {
      setModalAvisoVisible(true);
      setModalAvisoMensagem("Preencha todos os campos de endereço.");
      return false;
    }
    if (!isValidCEP(cep)) {
      setModalAvisoVisible(true);
      setModalAvisoMensagem(
        "CEP inválido. Certifique-se de que o CEP contém 8 dígitos."
      );
      return false;
    }
    return true;
  };

  const handleCardChange = (event) => {
    const { name, value } = event.target;
    setCardData((prevCardData) => ({
      ...prevCardData,
      [name]: value,
    }));
  };

  const validateCardNumber = (number) => {
    const cardInfo = cardValidator.number(number);
    if (cardInfo.card) {
      return cardInfo.card.type;
    }
  };

  const validateCVV = (cvv) => {
    return /^[0-9]{3}$/.test(cvv);
  };

  const validateExpirationDate = (month, year) => {
    const currentDate = new Date();
    const cardDate = new Date(`${year}-${month}-01`);
    return cardDate > currentDate;
  };




  const handleRemoveFromCart = (productId) => {
    removeFromCart(productId);
  };

  const formattedCurrency = (value) => {
    return value.toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
      minimumFractionDigits: 2,
    });
  };

  const handleMenuItemClick = (menuItem) => {
    if (activeMenuItem === "dados-pessoais" && menuItem === "endereço") {
      if (validatePersonalData()) {
        setActiveMenuItem(menuItem);
      }
    } else if (activeMenuItem === "endereço" && menuItem === "pagamento") {
      if (validateAddress()) {
        setActiveMenuItem(menuItem);
      }
    } else {
      setActiveMenuItem(menuItem);
    }
  };

  const isValidCPF = (cpf) => {
    if (!cpf || cpf.length !== 14) {
      return false;
    }

    const sanitizedCPF = cpf.replace(/[^\d]/g, "");
    if (
      sanitizedCPF === "00000000000" ||
      sanitizedCPF === "11111111111" ||
      sanitizedCPF === "22222222222" ||
      sanitizedCPF === "33333333333" ||
      sanitizedCPF === "44444444444" ||
      sanitizedCPF === "55555555555" ||
      sanitizedCPF === "66666666666" ||
      sanitizedCPF === "77777777777" ||
      sanitizedCPF === "88888888888" ||
      sanitizedCPF === "99999999999"
    ) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(sanitizedCPF.substring(i - 1, i)) * (11 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(sanitizedCPF.substring(9, 10))) {
      return false;
    }

    sum = 0;

    for (let i = 1; i <= 10; i++) {
      sum += parseInt(sanitizedCPF.substring(i - 1, i)) * (12 - i);
    }

    remainder = (sum * 10) % 11;

    if (remainder === 10 || remainder === 11) {
      remainder = 0;
    }

    if (remainder !== parseInt(sanitizedCPF.substring(10, 11))) {
      return false;
    }

    return true;
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (email.toLowerCase().endsWith(".online")) {
      return true;
    }

    return emailRegex.test(email);
  };

  const isValidCEP = (cep) => {
    const cepRegex = /^\d{8}$/;
    return cepRegex.test(cep);
  };

  const calcularPrazoEntrega = async () => {
    setIsLoading(true);
    const dadosPedido = {
      cepDestino: addressData.cep,
      products: cartItems,
    };
    try {
      const response = await axios.post(
        configs.api + "prazoCorreio",
        dadosPedido
      );
      if (response.status) {
        const dataMaximaSedex = new Date(response.data.sedex.prazo?.dataMaxima);
        const diaSedex = String(dataMaximaSedex.getDate()).padStart(2, "0");
        const mesSedex = String(dataMaximaSedex.getMonth() + 1).padStart(
          2,
          "0"
        );
        setFeriado(response.data.feriado);
        const hoje = new Date();
        const sextaFeira = hoje.getDay() === 5;
        if (isBefore13h && sextaFeira) {
          setSextaFeira(sextaFeira);
        }
        if (response.data.feriado.hoje === true && response.data.feriado.amanha === true) {
          setFeriado2(true);
        }


        const anoSedex = dataMaximaSedex.getFullYear();
        const dataMaximaPac = new Date(response.data.pac.prazo?.dataMaxima);
        const diaPac = String(dataMaximaPac.getDate()).padStart(2, "0");
        const mesPac = String(dataMaximaPac.getMonth() + 1).padStart(2, "0");
        const anoPac = dataMaximaPac.getFullYear();
        setPrazoEntregaSedex(`${diaSedex}/${mesSedex}/${anoSedex}` ?? "");
        setPrecoEntregaSedex(response.data.sedex.preco?.pcFinal ?? "");
        setPrazoEntregaPac(`${diaPac}/${mesPac}/${anoPac}` ?? "");
        setPrecoEntregaPac(response.data.pac.preco?.pcFinal ?? "");
        setOnlyMotoboy(false);
        setIsLoading(false);
        const agora = new Date();
        const trezeHoras = new Date();
        trezeHoras.setHours(13, 0, 0, 0);
        setIsBefore13h(agora < trezeHoras);
      } else {
        setOnlyMotoboy(true);
        setIsLoading(false);
        setModalAvisoVisible(true);
        setModalAvisoMensagem(response.erro);

        console.error("Erro ao calcular o prazo de entrega");
      }
    } catch (error) {
      setIsLoading(false);
      setOnlyMotoboy(true);
      console.error("Erro ao calcular o prazo de entrega", error);
    }
  };
  const handleTipoEntregaChange = (service) => {
    setEntregaAmanha(false);
    setEntregaHoje(false);
    setTipoEntrega(service);
    let deliveryCost = 0;
    if (tipoEntrega === "pac") {
      let precoEntregaAsString = precoEntregaPac.toString();

      let precoEntregaAsNumber = parseFloat(
        precoEntregaAsString.replace(",", ".")
      );
      deliveryCost = precoEntregaAsNumber;
      setPrecoEntrega(deliveryCost);
    } else if (tipoEntrega === "sedex") {
      let precoEntregaAsString = precoEntregaSedex.toString();

      let precoEntregaAsNumber = parseFloat(
        precoEntregaAsString.replace(",", ".")
      );

      deliveryCost = precoEntregaAsNumber;
      setPrecoEntrega(deliveryCost);
    } else if (tipoEntrega === "motoboy") {
      deliveryCost = 35.0;
      setPrecoEntrega(deliveryCost);
    }

  };
  useEffect(() => {
    const dadosPessoais = async () => {
      const url = `${configs.api}client/` + id;
      try {
        if (dataUsers == null) {
          setIsLoading2(true);
        }
        const token = Cookies.get("authToken");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        const response = await axios.get(url, { headers });
        const fetchedDataUsers = response.data;
        setDataUsers(fetchedDataUsers);
        setDadosPessoaisPreenchidos(true);

        setIsLoading2(false);
      } catch (error) {
        console.error("Error fetching clients:", error);
      }
    };
    if (!dataUsers && id) {
      dadosPessoais();
    }
  }, [dataUsers, id]);
  const handleFinalizarCompra = async (dados) => {
    try {
      setIsLoading(true);
      setIsFinalizarCompra(false);
      if (subTotal === 0) {
        setModalAvisoVisible(true);
        setModalAvisoMensagem("Não existe produto há ser pago");
        setIsLoading(false);
        window.open("/shop")
        return;
      } else {

        const public_key = await axios.get(configs.api + "public-key");
        const cartItemsCopy = JSON.parse(JSON.stringify(cartItems));
        const dados = {
          cartItems: cartItemsCopy,
          tipoPagamento: activeMenuItemPay,
          method: activeMenuItemPay,
          valueTotal: totalOrder,
          freteValue: precoEntrega,
          tipoEntrega: tipoEntrega,
          usuario: {
            ...formData,
          },
          endereco: {
            ...addressData,
          },
          entrega: {
            hoje: entregaHoje,
            amanha: entregaAmanha,
          }
        };

        if (activeMenuItemPay === "credit_card") {
        
          if (!cardData.number || cardData.number.replace(/\s/g, '').length !== 16) {
            setModalAvisoVisible(true);
            setModalAvisoMensagem("Número do cartão inválido.");
            setIsLoading(false);
            setIsFinalizarCompra(true);
            return ;
          }
      
          if (!validateCardNumber(cardData.number)) {
            setModalAvisoVisible(true);
            setModalAvisoMensagem("Número do cartão inválido.");
            setIsLoading(false);
            setIsFinalizarCompra(true);
            return ;
          }
      
          if (!validateCVV(cardData.securityCode)) {
            setModalAvisoVisible(true);
            setModalAvisoMensagem("CVV inválido.");
            setIsLoading(false);
            setIsFinalizarCompra(true);
            return;
          }
      
      
          if (!validateExpirationDate(cardData.expMonth, cardData.expYear)) {
            setModalAvisoVisible(true);
            setModalAvisoMensagem("Data de vencimento inválida.");
            setIsLoading(false);
            setIsFinalizarCompra(true);
            return ;
          }
      
          if (!cardData.holder) {
            setModalAvisoVisible(true);
            setModalAvisoMensagem("Nome é obrigatório.");
            setIsLoading(false);
            setIsFinalizarCompra(true);
            return ;
          }
      
          if (!cardData.installments) {
            setModalAvisoVisible(true);
            setModalAvisoMensagem("Parcelamento é obrigatório.");
            setIsLoading(false);
            setIsFinalizarCompra(true);
            return ;
          }

          if (!isValidCPF(cardData.cpfCnpj)) {
            setModalAvisoVisible(true);
            setModalAvisoMensagem("CPF inválido. Verifique o formato.");
            setIsLoading(false);
            setIsFinalizarCompra(true);
            return;
          }
         

          const card = window.PagSeguro.encryptCard({
            publicKey: public_key.data.public_key,
            holder: cardData.holder,
            number: cardData.number.replace(/\s/g, ""),
            expMonth: cardData.expMonth,
            expYear: cardData.expYear,
            securityCode: cardData.securityCode,
          });
          const encrypted = card.encryptedCard;
          dados.card = encrypted;
          dados.installments = cardData.installments;
          dados.holder = cardData.holder;
          dados.securityCode = cardData.securityCode;
          dados.cpf_cnpj = cardData.cpfCnpj;
        }

        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        };


        const response = await axios.post(configs.api + "order", dados, config);
        if (activeMenuItemPay === "pix") {
          setPagePix(true);
        } else if (activeMenuItemPay === "bank_slip") {
          setPageBoleto(true);
        } else {
          setPageCartao(true);
        }
        clearCart();
        setPaymentOrder(response.data.paymentOrder);
        setIsLoading(false);
      }

    } catch (error) {
      setModalAvisoVisible(true);
      setModalAvisoMensagem(error.response.data.message);
      setIsLoading(false);
      setIsFinalizarCompra(true);

    }
  };
  const handleMenuItemPay = (menuItemPay) => {
    setActiveMenuItemPay(menuItemPay);
    setShowFinalizarCompra(true);
  };

  const renderContent = () => {
    switch (activeMenuItem) {
      case "dados-pessoais":
        return renderDadosPessoais();
      case "endereço":
        return renderEndereco();
      case "pagamento":
        return renderPagamento();
      default:
        return null;
    }
  };

  const renderDadosPessoais = () => {
    return isLoading2 ? (
      <SectionDados>
        <div
          style={{
            position: "absolute",
            left: 100,
            top: 250,
          }}
        >
          <Loader2 />
        </div>
      </SectionDados>
    ) : (
      <SectionDados>
        <TitleDp>DADOS PESSOAIS</TitleDp>
        <p style={{
          fontSize: "14px",
          fontFamily: 'Thasadith',
          fontStyle: "normal",
          fontWeight: "800",
          lineHeight: "normal"
        }}>
          Solicitamos apenas as informações essenciais
          <br />
          para a realização da compra.
        </p>
        <DivInputs>
          <InputDados
            type="text"
            placeholder="Nome"
            name="nome"
            disabled={true}
            value={formData.nome}
            onChange={handleFormChange}
            style={{
              border: formData.nome !== "" ? "1px solid black" : "1px solid red",
              borderRadius: "20px",
            }}
          />
          <InputDados
            type="text"
            placeholder="Email"
            name="email"
            disabled={true}
            value={formData.email}
            onChange={handleFormChange}
            style={{
              border:
                formData.email !== "" ? "1px solid black" : "1px solid red",
              borderRadius: "20px",
            }}
          />
          <InputMask
            mask="999.999.999-99"
            alwaysShowMask={false}
            formatChars={{ 9: "[0-9]" }}
            placeholder="CPF"
            name="cpf_cnpj"
            disabled={true}
            value={formData.cpf_cnpj}
            onChange={handleFormChange}
            style={{
              width: windowWidth < 768 ? "100%" : "54%",
              border:
                formData.cpf_cnpj !== "" ? "1px solid black" : "1px solid red",
              borderRadius: "20px",
            }}
          />
          <InputMask
            mask="(99) 99999-9999"
            alwaysShowMask={false}
            formatChars={{ 9: "[0-9]" }}
            placeholder="Telefone"
            name="telefone"
            value={formData.telefone}
            onChange={handleFormChange}
            style={{
              width: windowWidth < 768 ? "100%" : "54%",
              border:
                formData.telefone !== "" ? "1px solid black" : "1px solid red",
              borderRadius: "20px",
            }}
          />
        </DivInputs>
        <ContentPerson>
          <Section>
            {/* <p>Incluir dados de pessoas jurídica</p> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <InputRadio type="radio" name="radio" id="" checked />
              <span style={{
                fontSize: "14px",
                fontFamily: 'Thasadith',
                fontStyle: "normal",
                fontWeight: "800",
                lineHeight: "normal"
              }}>Quero receber e-mails com promoções</span>
            </div>
          </Section>
          <Button
            style={{
              backgroundColor: "#000", color: "#fff", fontSize: "14px",
              fontFamily: 'Thasadith',
              fontStyle: "normal",
              fontWeight: "800",
              lineHeight: "normal"
            }}
            type="submit"
            active={activeMenuItem === "endereço"}
            activeColor={activeMenuItem === "endereço"}
            onClick={() => {
              if (dadosPessoaisPreenchidos) {
                handleMenuItemClick("endereço");
              } else {
                setModalAvisoVisible(true);
                setModalAvisoMensagem(
                  "Preencha todos os campos de dados pessoais."
                );
              }
            }}
          >
            Ir para o endereço
          </Button>
        </ContentPerson>
      </SectionDados >
    );
  };

  const renderEndereco = () => {
    return (
      <SectionEndereco>
        <ContentAdress>
          <TitleAdress>ENDEREÇO</TitleAdress>
          <DivInputsAdressOne>
            <InputMask
              id="cep"
              placeholder="00000-000"
              mask="99999-999"
              style={{
                marginTop: "10px",
                padding: "0 0 0 14px",
                width: windowWidth < 768 ? "100%" : "80%",
                marginRight: "15px",
                border:
                  addressData.cep !== "" ? "1px solid black" : "1px solid red",
                borderRadius: "20px",
              }}
              maskChar="_"
              alwaysShowMask={false}
              formatChars={{ 9: "[0-9]" }}
              className="col-6"
              name="cep"
              value={addressData.cep}
              onChange={handleAddressChange}
            />


            <Button2 onClick={buscarEndereco}>Buscar Endereço</Button2>

          </DivInputsAdressOne>
          <span><a style={{ textDecoration: "none", color: "black", cursor: "pointer", fontSize: "14px" }} rel="noreferrer" target="_blank" href="https://buscacepinter.correios.com.br/app/endereco/index.php?t">Não sei meu cep</a></span>

          <DivInputsAdress>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                id="logradouro"
                type="text"
                placeholder="Logradouro"
                name="logradouro"
                disabled="true"
                value={addressData.logradouro}
                onChange={handleAddressChange}
                mask="99999-999"
                style={{
                  width: "70%",
                  margin: "2px",
                  border:
                    addressData.logradouro !== "" ? "1px solid black" : "1px solid red",
                  borderRadius: "20px 0 0 20px",
                  padding: "0 0 0 10px",
                }}
              />
              <input
                mask="999999"
                formatChars={{ 9: "[0-9]" }}
                placeholder="Número"
                name="numero"
                value={addressData.numero}
                onChange={handleAddressChange}
                style={{
                  width: "30%",
                  margin: "2px",
                  border:
                    addressData.numero !== "" ? "1px solid black" : "1px solid red",
                  borderRadius: "0 20px 20px 0",
                  padding: "0 0 0 10px",
                }}
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                id="bairro"
                type="text"
                placeholder="Bairro"
                name="bairro"
                disabled="true"
                value={addressData.bairro}
                onChange={handleAddressChange}
                style={{
                  width: "70%",
                  margin: "2px",
                  border:
                    addressData.bairro !== ""
                      ? "1px solid black"
                      : "1px solid red",
                  borderRadius: "20px 0 0 20px",
                  padding: "0 0 0 10px",
                }}
              />
              <input
                id="complemento"
                type="text"
                placeholder="Complemento"
                name="complemento"
                value={addressData.complemento}
                onChange={handleAddressChange}
                style={{
                  margin: "2px",
                  border: "1px solid black",
                  width: "30%",
                  borderRadius: "0px 20px 20px 0px",
                  padding: "0 0 0 10px",
                }}
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                id="cidade"
                type="text"
                placeholder="Cidade"
                name="cidade"
                disabled="true"
                value={addressData.cidade}
                onChange={handleAddressChange}
                style={{
                  margin: "2px",
                  width: "100%",
                  border:
                    addressData.cidade !== ""
                      ? "1px solid black"
                      : "1px solid red",
                  borderRadius: "20px 0 0 20px",
                  padding: "0 0 0 10px",
                }}
              />
              <input
                id="estado"
                type="text"
                placeholder="Estado"
                name="estado"
                disabled="true"
                value={addressData.estado}
                onChange={handleAddressChange}
                style={{
                  width: "100%",
                  margin: "2px",
                  border:
                    addressData.estado !== ""
                      ? "1px solid black"
                      : "1px solid red",
                  borderRadius: "0 20px 20px 0",
                  padding: "0 0 0 10px",
                }}
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <input
                type="text"
                className="input3"
                placeholder="Destinatário"
                name="destinatario"
                value={addressData.destinatario}
                onChange={handleAddressChange}
                style={{
                  width: "100%",
                  border:
                    addressData.destinatario !== ""
                      ? "1px solid black"
                      : "1px solid red",
                  borderRadius: "20px",
                  padding: "0 0 0 10px",
                }}
              />
            </div>
          </DivInputsAdress>
          <SectionAdress style={{ width: "100%" }}>
            <TitleAd>FRETE</TitleAd>
            <DivInputsAdressOne>
              {precoEntregaSedex && prazoEntregaSedex ? (
                <div style={{ width: "100%" }}>
                  <div style={{ alignItems: "center", display: "flex", width: "100%" }}>
                    {addressData.cidade === "São Paulo" &&
                      addressData.estado === "SP" && (!feriado2) && !sextaFeira && (
                        <ButtonLabel
                          isActive={tipoEntrega === "motoboy"}
                          onClick={() => handleTipoEntregaChange("motoboy")}
                        >
                          Motoboy
                        </ButtonLabel>
                      )}
                    <ButtonLabel
                      isActive={tipoEntrega === "sedex"}
                      onClick={() => handleTipoEntregaChange("sedex")}
                    >
                      Sedex
                    </ButtonLabel>
                    <ButtonLabel
                      isActive={tipoEntrega === "pac"}
                      onClick={() => handleTipoEntregaChange("pac")}
                    >
                      PAC
                    </ButtonLabel>
                  </div>

                  {tipoEntrega === "sedex" && (
                    <div style={{ width: "100%" }}>
                      <p style={{ textAlign: "left", fontSize: "14px" }}>
                        Preço de entrega: <b>R$: {precoEntregaSedex}</b>
                      </p>
                      <p style={{ textAlign: "left", fontSize: "14px" }}>
                        Prazo de entrega: <b>{prazoEntregaSedex}</b>
                      </p>
                    </div>
                  )}
                  {tipoEntrega === "motoboy" && (
                    <div style={{ width: "100%" }}>
                      <p style={{ textAlign: "left", fontSize: "14px" }}>
                        Preço de entrega: <b>R$: 35,00</b>
                      </p>
                      {!isBefore13h && sextaFeira ? (
                        <div style={{ width: "100%" }}>
                          <p style={{ textAlign: "left", fontSize: "14px" }}>
                            Escolha a opção de entrega:
                          </p>
                          <div style={{ display: "flex", fontSize: "14px", width: "100%" }}>
                            {!feriado.amanha && (
                              <ButtonEntrega style={{
                                width: "100%", margin: "2px",
                                backgroundColor: entregaAmanha ? "black" : "gray",
                              }}
                                onClick={() => handleEntregaAmanha(true)}
                              >
                                Entrega amanhã ({nextDate})
                              </ButtonEntrega>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <p style={{ textAlign: "left", fontSize: "14px" }}>
                            Escolha a opção de entrega:
                          </p>
                          <div style={{ display: "flex", fontSize: "14px", width: "100%" }}>
                            {isBefore13h && !feriado.hoje && (
                              <ButtonEntrega style={{
                                width: "100%", margin: "2px",
                                backgroundColor: entregaHoje ? "black" : "gray",
                                border: entregaHoje ? "1px solid black" : "1px solid gray",
                              }}
                                onClick={() => handleEntregaHoje(true)}
                              >
                                Entrega hoje ({currentDate})
                              </ButtonEntrega>
                            )}
                            {!feriado.amanha && (
                              <ButtonEntrega style={{
                                width: "100%", margin: "2px",
                                backgroundColor: entregaAmanha ? "black" : "gray",
                              }}
                                onClick={() => handleEntregaAmanha(true)}
                              >
                                Entrega amanhã ({nextDate})
                              </ButtonEntrega>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  {tipoEntrega === "pac" && (
                    <div style={{ width: "100%" }}>
                      <p style={{ textAlign: "left", fontSize: "14px" }}>
                        Preço de entrega: <b>R$: {precoEntregaPac}</b>
                      </p>
                      <p style={{ textAlign: "left", fontSize: "14px" }}>
                        Prazo de entrega: <b>{prazoEntregaPac}</b>
                      </p>
                    </div>
                  )}
                </div>
              ) : isLoading ? (
                <Loader />
              ) : (
                <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                  <ButtonMobile
                    style={{ backgroundColor: "#000", color: "#fff" }}
                    onClick={calcularPrazoEntrega}
                  >
                    Calcular Prazo de Entrega
                  </ButtonMobile>

                  {onlyMotoboy && addressData.cidade === "São Paulo" &&
                    addressData.estado === "SP" && (!feriado2) && !sextaFeira && (
                      <ButtonMobile style={{ backgroundColor: "#000", color: "#fff" }}
                        isActive={tipoEntrega === "motoboy"}
                        onClick={() => handleTipoEntregaChange("motoboy")}>
                        Motoboy
                      </ButtonMobile>
                    )}

                  {onlyMotoboy && tipoEntrega === "motoboy" && (
                    <div style={{ width: "100%" }}>
                      <p style={{ textAlign: "left", fontSize: "14px" }}>
                        Preço de entrega: <b>R$: 35,00</b>
                      </p>
                      {!isBefore13h && sextaFeira ? (
                        <div style={{ width: "100%" }}>
                          <p style={{ textAlign: "left", fontSize: "14px" }}>
                            Escolha a opção de entrega:
                          </p>
                          <div style={{ display: "flex", fontSize: "14px", width: "100%" }}>
                            {!feriado.amanha && (
                              <ButtonEntrega style={{
                                width: "100%", margin: "2px",
                                backgroundColor: entregaAmanha ? "black" : "gray",
                              }}
                                onClick={() => handleEntregaAmanha(true)}
                              >
                                Entrega amanhã ({nextDate})
                              </ButtonEntrega>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div style={{ width: "100%" }}>
                          <p style={{ textAlign: "left", fontSize: "14px" }}>
                            Escolha a opção de entrega:
                          </p>
                          <div style={{ width: "100%" }}>
                            {isBefore13h && !feriado.hoje && (
                              <ButtonEntrega style={{
                                width: "100%",
                                backgroundColor: entregaHoje ? "black" : "gray",
                                border: entregaHoje ? "1px solid black" : "1px solid gray",
                              }}
                                onClick={() => handleEntregaHoje(true)}
                              >
                                Entrega hoje ({currentDate})
                              </ButtonEntrega>
                            )}
                            {!feriado.amanha && (
                              <ButtonEntrega style={{
                                width: "100%",
                                backgroundColor: entregaAmanha ? "black" : "gray",
                              }}
                                onClick={() => handleEntregaAmanha(true)}
                              >
                                Entrega amanhã ({nextDate})
                              </ButtonEntrega>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
            </DivInputsAdressOne>
          </SectionAdress>

          <div>
            <Button
              style={{ backgroundColor: "#000", color: "#fff" }}
              type="submit"
              active={activeMenuItem === "pagamento"}
              activeColor={activeMenuItem === "pagamento"}
              onClick={() => {
                if (precoEntregaSedex === "") {
                  setModalAvisoVisible(true);
                  setModalAvisoMensagem(
                    "Calcule o frete antes de ir ao pagamento."
                  );
                } else if (tipoEntrega === "motoboy" && entregaAmanha === false && entregaHoje === false) {
                  setModalAvisoVisible(true);
                  setModalAvisoMensagem(
                    "Escolha quando entregar antes de ir ao pagamento."
                  );
                } else if (enderecoPreenchido) {
                  handleMenuItemClick("pagamento");
                } else {
                  setModalAvisoVisible(true);
                  setModalAvisoMensagem(
                    "Preencha todos os campos de endereço antes de finalizar a compra."
                  );
                }
              }}
            >
              Ir para o pagamento
            </Button>
          </div>
        </ContentAdress>
      </SectionEndereco>
    );
  };
  const buscarEndereco = async () => {
    setPrazoEntregaSedex(0);
    setPrecoEntregaSedex(0);
    setPrazoEntregaPac(0);
    setPrecoEntregaPac(0);
    setTipoEntrega("sedex");
    const cep = document.getElementById("cep").value.replace(/\D/g, "");
    if (cep.length !== 8) {
      setModalAvisoVisible(true);
      setModalAvisoMensagem(
        "CEP inválido. Certifique-se de que o CEP contém 8 dígitos."
      );
      return;
    }
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      if (response.data.erro) {
        setModalAvisoVisible(true);
        setModalAvisoMensagem("CEP não encontrado. Verifique o CEP digitado.");
        return;
      }
      const { logradouro, bairro, localidade, uf, complemento } = response.data;

      setAddressData({
        ...addressData,
        cep: cep,
        logradouro: logradouro,
        bairro: bairro,
        cidade: localidade,
        estado: uf,
        complemento: complemento,
      });
    } catch (error) {
      console.error("Erro ao buscar endereço:", error);
    }
  };
  const renderContentPay = () => {
    switch (activeMenuItemPay) {
      case "vale":
        return renderVale();
      case "credit_card":
        return renderCartao();
      case "bank_slip":
        return renderBoleto();
      case "pix":
        return renderPix();
      default:
        return null;
    }
  };

  const renderVale = () => {
    return (
      <>
        <HeaderMobile>
          <Title>VALE-TROCA</Title>
        </HeaderMobile>
        <SectionVale>
          <input type="text" placeholder="XXXXXX" />
        </SectionVale>
      </>
    );
  };

  const ButtonLabel = ({ isActive, onClick, children }) => {
    return (
      <label style={{ marginRight: "10px", marginTop: "10px" }}>
        <button
          style={{
            backgroundColor: isActive ? "black" : "gray",
            color: "white",
            border: "none",
            width: "100px",
            padding: "10px 15px",
            cursor: "pointer",
            borderRadius: "24px",
          }}
          onClick={onClick}
        >
          {children}
        </button>
      </label>
    );
  };

  const renderCartao = () => {
    return (
      <>
        <HeaderMobile>
          <Title>CARTÃO DE CRÉDITO</Title>
        </HeaderMobile>
        <SectionCartao>
          <div style={{ position: "relative", width: "100%", display: "flex", alignItems: "center" }}>
              <InputMask
                type="text"
                placeholder="2222 2222 2222 2222"
                mask="9999 9999 9999 9999"
                maskChar="_"
                name="number"
                alwaysShowMask={false}
                value={cardData.number}
                onChange={handleCardChange}
                style={{
                  margin: "10px",
                  borderRadius: "20px",
                  fontSize: "12px",
                  border: "1px solid black",
                  paddingRight: "40px", 
                }}
              />
              {validateCardNumber(cardData.number) && (
                <img
                  width={"30px"}
                  src={`/assets/imgs/${validateCardNumber(cardData.number)}.png`}
                  alt=""
                  style={{
                    position: "absolute",
                    right: "25px",
                    top: "50%",
                    transform: "translateY(-50%)", 
                  }}
                />
              )}
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <select
              name="installments"
              value={cardData.installments}
              onChange={handleCardChange}
              style={{
                borderRadius: '20px',
                fontSize: '12px',
                margin: '10px',
                border: '1px solid black',
                backgroundColor: "white",
              }}
            >
              {renderParcelas()}
            </select>
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <input
              type="text"
              name="holder"
              placeholder="Nome Impresso"
              value={cardData.holder}
              onChange={handleCardChange}
              style={{
                borderRadius: "20px",
                fontSize: "12px",
                border: "1px solid black",
                margin: "10px",

              }}
            />
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <InputMask
              placeholder="Mês"
              mask="99"
              style={{
                width: "33%",
                borderRadius: "20px 0 0 20px",
                border: "1px solid black",
                fontSize: "12px",
                margin: "10px",

              }}
              maskChar="_"
              alwaysShowMask={false}
              formatChars={{ 9: "[0-9]" }}
              name="expMonth"
              value={cardData.expMonth}
              onChange={handleCardChange}
            />
            <InputMask
              placeholder="Ano"
              mask="9999"
              style={{
                margin: "10px",
                width: "33%",
                borderRadius: " 0 20px 20px 0",
                border: "1px solid black",
                fontSize: "12px",
              }}
              maskChar="_"
              alwaysShowMask={false}
              formatChars={{ 9: "[0-9]" }}
              name="expYear"
              value={cardData.expYear}
              onChange={handleCardChange}
            />

            <InputMask
              placeholder="CVV"
              mask="999"
              maskChar="_"
              name="securityCode"
              alwaysShowMask={false}
              formatChars={{ 9: "[0-9]" }}
              value={cardData.securityCode}
              onChange={handleCardChange}
              style={{
                width: "33%",
                margin: "10px",
                borderRadius: "20px",
                border: "1px solid black",
                fontSize: "12px",
              }}
            />
          </div>
          <div style={{ display: "flex", width: "100%" }}>
            <InputMask
              placeholder="111.111.111-11"
              mask="999.999.999-99"
              name="cpfCnpj"
              value={cardData.cpfCnpj}
              onChange={handleCardChange}
              maskChar="_"
              alwaysShowMask={false}
              formatChars={{ 9: "[0-9]" }}
              style={{
                marginRight: "15px",
                borderRadius: "20px",
                border: "1px solid black",
                margin: "10px",
              }}

            />
          </div>
        </SectionCartao >
      </>
    );
  };

  const renderBoleto = () => {
    let boletoInfo = null;
    let links = null;

    if (
      paymentOrder &&
      paymentOrder.charges[0].payment_method.boleto &&
      paymentOrder.charges.length > 0
    ) {
      boletoInfo = paymentOrder.charges[0].payment_method.boleto;
      links = paymentOrder.charges[0].links[0];
    }

    return (
      <div>
        <HeaderMobile>
          <Title>BOLETO BANCÁRIO</Title>
        </HeaderMobile>

        {!boletoInfo && (
          <div style={{ padding: "20px" }}>
            <p
              style={{
                fontFamily: "Thasadith",
                fontStyle: "normal",
                lineHeight: "normal",
                fontWeight: "700",
                display: windowWidth > 1068 ? "block" : "none"
              }}
            >
              BOLETO BANCÁRIO
            </p>
            <p
              style={{
                fontSize: "16px",
                fontFamily: "Thasadith",
                fontStyle: "normal",
                lineHeight: "normal",
              }}
            >
              Para pagar com boleto bancário, clique em "Finalizar Pedido". O
              boleto será emitido e ficará disponível para pagamento, ele será
              enviado, também, via e-mail.
            </p>
          </div>
        )}

        {boletoInfo && (
          <>
            <HeaderMobile>
              <Title>BOLETO BANCÁRIO</Title>
            </HeaderMobile>
            <SectionBoleto>
              <ProductDetails>
                <p>VALOR DO BOLETO R$ {formattedCurrency(totalOrder)}</p>
                <p>Código de Barras: {boletoInfo.barcode}</p>
                <a href={links.href}>Link para boleto</a>
              </ProductDetails>
            </SectionBoleto>
          </>
        )}
      </div>
    );
  };

  const renderPix = () => {
    if (paymentOrder && paymentOrder?.qr_codes[0]) {
      const pixCode = paymentOrder.qr_codes[0];

      return (
        <>
          <HeaderMobile>
            <Title>PIX</Title>
          </HeaderMobile>
          <BoxPix>
            <ProductDetails>
              <p>VALOR DO PIX R$ {formattedCurrency(totalOrder)}</p>
              <textarea
                readOnly
                value={pixCode.text}
                style={{
                  width: "300px",
                  height: "100px",
                }}
              />
              <img
                src={pixCode.links[0].href || NoImage}
                alt=""
                style={{
                  width: "300px",
                  height: "auto",
                }}
              />
            </ProductDetails>
          </BoxPix>
        </>
      );
    }
    if (!paymentOrder) {
      return (
        <>
          <HeaderMobile>
            <Title>PIX</Title>
          </HeaderMobile>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img alt="pix" src={pix} />
            <p style={{ fontSize: "14px" }}>
              Para pagar com PIX, clique em "Finalizar Compra".
            </p>
          </div>
        </>
      );
    }
  };

  const renderPagamento = () => {
    return (
      <SectionPagamento>
        <ContentPay>
          <TitlePay>PAGAMENTO</TitlePay>
          <BoxPay>
            <MenuItemPay
              active={activeMenuItemPay === "credit_card"}
              onClick={() => handleMenuItemPay("credit_card")}
            >
              CARTÃO DE CRÉDITO
            </MenuItemPay>
            <MenuItemPay
              active={activeMenuItemPay === "bank_slip"}
              onClick={() => handleMenuItemPay("bank_slip")}
            >
              BOLETO BANCÁRIO
            </MenuItemPay>
            <MenuItemPay
              active={activeMenuItemPay === "pix"}
              onClick={() => handleMenuItemPay("pix")}
            >
              PIX <span style={{
                position: "absolute",
                color: "green",
                fontSize: "0.7em",
                margin: "2px",
              }}>(5% OFF)</span>
            </MenuItemPay>
          </BoxPay>
        </ContentPay>
        <RenderContentPay>{renderContentPay()}</RenderContentPay>
        {showFinalizarCompra && finalizarCompra && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <Button
              style={{
                backgroundColor: "#000",
                color: "#fff",
                width: "95%",
                display: windowWidth > 768 ? "none" : "block",
              }}
              type="button"
              onClick={handleFinalizarCompra}
            >
              Finalizar Pedido{" "}
            </Button>
          </div>
        )}
        {isLoading && (
          <Loader />
        )}
      </SectionPagamento>
    );
  };

  return (
    <Layout>
      {!pageBoleto && !pagePix && (
        <Header>
          <MenuItem
            active={activeMenuItem === "dados-pessoais"}
            activeColor={activeMenuItem === "dados-pessoais"}
            onClick={() => handleMenuItemClick("dados-pessoais")}
          >
            DADOS PESSOAIS
          </MenuItem>
          <MenuItem
            active={activeMenuItem === "endereço"}
            activeColor={activeMenuItem === "endereço"}
            onClick={() => {
              if (dadosPessoaisPreenchidos) {
                handleMenuItemClick("endereço");
              } else {
                setModalAvisoVisible(true);
                setModalAvisoMensagem(
                  "Preencha todos os campos de dados pessoais antes de finalizar a compra."
                );
              }
            }}
          >
            ENDEREÇO
          </MenuItem>
          <MenuItem
            active={activeMenuItem === "pagamento"}
            activeColor={activeMenuItem === "pagamento"}
            onClick={() => {
              if (enderecoPreenchido) {
                handleMenuItemClick("pagamento");
              } else {
                setModalAvisoVisible(true);
                setModalAvisoMensagem(
                  "Preencha todos os campos de endereço antes de finalizar a compra."
                );
              }
            }}
          >
            PAGAMENTO
          </MenuItem>
        </Header>
      )}
      <Content>
        {pagePix && <PagamentoPix dados={paymentOrder} />}
        {pageCartao && <PagamentoCartao dados={paymentOrder} />}
        {pageBoleto && <PagamentoBoleto dados={paymentOrder} />}
        {!pageBoleto && !pagePix && !pageCartao && renderContent()}
        {!pageBoleto && !pagePix && !pageCartao && (
          <Div2>
            <BoxDiv2>
              <Title>Resumo do pedido</Title>
            </BoxDiv2>
            {cartItems.map((item) => (
              <SectionProduct>
                <ProductImage>
                  <img src={item.images[0]?.path || NoImage} alt="" />
                </ProductImage>
                <div style={{ width: "100%" }}>
                  <ProductDetails>
                    <p>{item.name.replace(/-/g, "")}</p>
                    <p>Tamanho: {item.size}</p>
                    <p>Cor: {item.color}</p>
                  </ProductDetails>
                  <ProductPrice>
                    <p>{formatCurrency(item.salePrice)}</p>
                  </ProductPrice>
                </div>
                <RemoveButton onClick={() => handleRemoveFromCart(item.id)}>
                  <BsTrash></BsTrash>
                </RemoveButton>
              </SectionProduct>
            ))}
            <SectionEnd>
              <BackCart
                onClick={() => navigate("/cart")}
                style={{ cursor: "pointer" }}
              >
                VOLTAR AO CARRINHO
              </BackCart>
            </SectionEnd>
            <SectionEnd style={{ padding: "5px" }}>
              <SectionSub>
                <TitleEnd>SUB TOTAL</TitleEnd>
                <TitleEnd>{formattedCurrency(subTotal)}</TitleEnd>
              </SectionSub>
            </SectionEnd>
            {activeMenuItemPay === "pix" && (
              <SectionEnd style={{ padding: "5px" }}>
                <SectionSub>
                  <TitleEnd style={{ fontWeight: "400" }}>DESCONTO</TitleEnd>
                  <TitleEnd style={{ fontWeight: "400" }}>
                    {desconto ? (
                      <><span style={{ textDecoration: "line-through", color: "red" }}>{formattedCurrency(desconto)}</span></>
                    ) : (
                      <>R$ 0,00</>
                    )}
                  </TitleEnd>
                </SectionSub>
              </SectionEnd>
            )}

            <SectionEnd style={{ padding: "5px" }}>
              <SectionSub>
                <TitleEnd style={{ fontWeight: "400" }}>FRETE</TitleEnd>
                <TitleEnd style={{ fontWeight: "400" }}>
                  {precoEntrega ? (
                    <>{formattedCurrency(precoEntrega)}</>
                  ) : (
                    <>R$ 0,00</>
                  )}
                </TitleEnd>
              </SectionSub>
            </SectionEnd>

            <SectionEnd style={{ padding: "5px" }}>
              <SectionSub>
                <TitleEnd style={{ fontWeight: "400" }}>TOTAL</TitleEnd>
                <TitleEnd style={{ fontWeight: "400" }}>
                  {totalOrder && !isNaN(totalOrder) ? (
                    <>{formattedCurrency(totalOrder)}</>
                  ) : (
                    <>R$ 0,00</>
                  )}
                </TitleEnd>
              </SectionSub>
            </SectionEnd>
            {showFinalizarCompra && finalizarCompra && (
              <div>
                <Button
                  style={{ backgroundColor: "#000", color: "#fff" }}
                  type="button"
                  onClick={handleFinalizarCompra}
                >
                  Finalizar Pedido{" "}
                </Button>
              </div>
            )}
            {isLoading && showFinalizarCompra && <Loader />}
          </Div2>
        )}
        {visibleResumo && (
          <DivMobile>
            <BoxDiv2>
              <Title>Resumo do pedido</Title>
            </BoxDiv2>
            {cartItems.map((item) => (
              <SectionProduct>
                <ProductImage>
                  <img src={item.images[0]?.path || NoImage} alt="" />
                </ProductImage>
                <div style={{ width: "100%" }}>
                  <ProductDetails>
                    <p>{item.name.replace(/-/g, "")}</p>
                    <p>Tamanho: {item.size}</p>
                    <p>Cor: {item.color}</p>
                  </ProductDetails>
                  <ProductPrice>
                    <p>{formatCurrency(item.salePrice)}</p>
                  </ProductPrice>
                </div>
                <RemoveButton onClick={() => handleRemoveFromCart(item.id)}>
                  <BsTrash></BsTrash>
                </RemoveButton>
              </SectionProduct>
            ))}
            <SectionEnd>
              <BackCart
                onClick={() => navigate("/cart")}
                style={{ cursor: "pointer" }}
              >
                VOLTAR AO CARRINHO
              </BackCart>
            </SectionEnd>
            <SectionEnd style={{ padding: "5px" }}>
              <SectionSub>
                <TitleEnd>SUB TOTAL</TitleEnd>
                <TitleEnd>{formattedCurrency(subTotal)}</TitleEnd>
              </SectionSub>
            </SectionEnd>
            {activeMenuItemPay === "pix" && (
              <SectionEnd style={{ padding: "5px" }}>
                <SectionSub>
                  <TitleEnd style={{ fontWeight: "400" }}>DESCONTO</TitleEnd>
                  <TitleEnd style={{ fontWeight: "400" }}>
                    {desconto ? (
                      <>{formattedCurrency(desconto)}</>
                    ) : (
                      <>R$ 0,00</>
                    )}
                  </TitleEnd>
                </SectionSub>
              </SectionEnd>
            )}
            <SectionEnd style={{ padding: "5px" }}>
              <SectionSub>
                <TitleEnd style={{ fontWeight: "400" }}>FRETE</TitleEnd>
              </SectionSub>
            </SectionEnd>
            <SectionEnd style={{ padding: "5px" }}>
              <SectionSub>
                <TitleEnd style={{ fontWeight: "400" }}>TOTAL</TitleEnd>
                <TitleEnd style={{ fontWeight: "400" }}>
                  {!isNaN(totalOrder) ? (
                    <>{formattedCurrency(totalOrder)}</>
                  ) : (
                    <>R$ 0,00</>
                  )}
                </TitleEnd>
              </SectionSub>
            </SectionEnd>
            {showFinalizarCompra && finalizarCompra && (
              <div>
                <Button
                  style={{ backgroundColor: "#000", color: "#fff" }}
                  type="button"
                  onClick={handleFinalizarCompra}
                >
                  Finalizar Pedido{" "}
                </Button>
              </div>
            )}
            {isLoading && showFinalizarCompra && <Loader />}
          </DivMobile>
        )}

        {modalAvisoVisible && (
          <ModalAviso
            visible={modalAvisoVisible}
            mensagem={modalAvisoMensagem}
            onClose={closeModalAviso}
            showOverlay={true}
          />
        )}
      </Content>
      {/* <ButtonMobile onClick={handleVisibleResumo}>Ver Resumo</ButtonMobile> */}
    </Layout>
  );
};

export default Checkout;
